<template>
    <SiteHeader ref="siteHeader" @loginSuccess="fetchData()" />
    <section class="main-content relative flex mt-25">
        <template v-if="uiStates.connection.isLoggedIn && uiStates.dataReady">
            <router-view />
        </template>
        <template v-else>
            <div class="flex w-full justify-center mt-6 mx-6 px-6 py-52 2xl:py-80 bg-black bg-opacity-50 rounded-3xl text-white">
                <span v-if=" ! uiStates.connection.isLoggedIn" class="text-lg font-vinhan">Please 
                    <a href="#" @click.prevent="$refs.siteHeader.performLogin()" class="underline">login</a> 
                    to continue</span>
                <span v-else class="text-lg font-vinhan">Loading data, please wait...</span>
            </div>
        </template>
    </section>
    <LightBox ref="loadingBox" :backdrop-close="false" :show-on-created="false" z-index="100"  
        modal-class="inline-block text-white">

        <span class="loading-ico inline-block">
            <svg class="animate-spin h-10 w-10 " xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
        </span>
        <span class="text-xl relative ml-4 -top-2.5 font-bold">Processing...</span>
    </LightBox>
</template>

<script>
import SiteHeader from '@/components/global/SiteHeader.vue';
import LightBox from '@/components/global/LightBox.vue';
import API from '@/helpers/Api.js';
import Web3 from 'web3';
import SMC from '@/helpers/SMC.js';
import ShareMethods from '@/mixins/ShareMethods.js';

const Vue = require('vue');

export default {
    name: 'Main',
    mixins: [ShareMethods],
    components: {
        SiteHeader,
        LightBox
    },
    data() {
        return {
            user: {},
            birds: [],
            lands: [],
            eggs: [],
            tools: [],
            tokens: {
                FAC: null,
                KUB: null,
            },
            uiStates: {
                dataReady: false,
                page: 'my-account',
                screen: 'inventory', // enum inventory, activities
                subScreen: 'birds', // enum birds birds-corpse lands eggs

                connection: {
                    walletConnected: false,
                    isLoggedIn: false,
                    walletAddress: null,
                    loggedInToken: null,
                    expireAt: null,
                }
            },
            eth: {
                connected: null,
                walletAddress: null,
                w3: null,
            },
        }
    },
    created() {
        // Restore connection status from session storage
        let connection = sessionStorage.getItem('connection');
        if (connection) {
            try {
                connection = JSON.parse(connection);
                this.uiStates.connection = connection;
                console.log('Found connection info in sessionStorage', connection);

                if (connection.loggedInToken && connection.expireAt > Date.now()) {
                    console.log('Token is not expired yet');

                    API.setToken(connection.loggedInToken);
                    API.bindConnectionState(this.uiStates.connection);

                } else {
                    console.log('Logged in token is expired');
                    
                    this.uiStates.connection.isLoggedIn = false;
                    this.uiStates.connection.loggedInToken = null; 
                    this.uiStates.connection.expireAt = null;
                }
                
            } catch (ex) {
                console.error(ex);
            }
        }
    },
    async mounted() {
        // "providing" after mounted due to ref only available after element being mounted
        Vue.provide('loadingBox', this.$refs.loadingBox);

        if (this.uiStates.connection && this.uiStates.connection.isLoggedIn) {
            
            this.fetchData();

            // rebind eth object for logged in case
            console.log('Re-connecting to Metamask on session resumed.');
            
            if ( ! this.eth.connected && typeof window.ethereum !== 'undefined') {
                // Instance web3 with the provided information
                let web3 = new Web3(window.ethereum);
                
                try {
                    // Request account access
                    await window.ethereum.enable();
                    window.w3 = web3;
                    this.eth.connected = true;
                    this.eth.w3 = web3;
                    
                    let account = await window.ethereum.request({ method: 'eth_accounts' });
                    this.eth.walletAddress = account.toString();

                    // Fetch token from wallet
                    await this.updateTokens();
                     
                } catch(e) {
                    
                    console.error('Connect Metamask fail', e);
                }
            }
        }
    },
    watch: {
        'uiStates.connection': {
            handler(val) {
                console.log('uiStates connection change, save to session storage', val);

                sessionStorage.setItem('connection', JSON.stringify(val));
            },
            deep: true,
        }
    },
    methods: {
        switchTab(tab) {
            this.uiStates.subScreen = tab;
        },
        async fetchData() {
            try {
                this.$refs.loadingBox.show();

                const data = await API.me();
                console.log('/ME resp', data);
                this.tools = data.tool;
                this.birds = data.birds;
                this.lands = data.isLands;
                this.eggs = data.eggs;
                this.uiStates.dataReady = true;
                
                this.user.id = data.id;

                this.$refs.loadingBox.hide();
            } catch (ex) {
                console.error('Fetch initial data Fail', ex);
                this.$refs.loadingBox.hide();
            }
        },        
    },
    provide() {
        return {
            uiStates: Vue.computed(() => this.uiStates),
            eth: Vue.computed(() => this.eth),
            birds: Vue.computed(() => this.birds),
            lands: Vue.computed(() => this.lands),
            eggs: Vue.computed(() => this.eggs),
            tools: Vue.computed(() => this.tools),
            user: Vue.computed(() => this.user),
            tokens: Vue.computed(() => this.tokens), 
        }
    },
}

// Dont remove the bellow css class, it's for tailwind's purge system to recognize as it's being used
// bg-common bg-rare bg-epic bg-legendary

</script>

<style lang="postcss">

.container {
    width: calc(100% - 30px);
}
@screen xl {
    .container {
        width: 864px;
    }
} 
@screen 2xl {
    .container {
        width: 1150px;
    }
}

.main-nav .link {
    transition: background-color 0.3s linear;
}
.main-nav .link:hover,
.main-nav .link.active,
.main-nav .link.router-link-active {
    @apply bg-purple-dark;
}

.sub-nav .link {
    transition: all 0.3s linear;
}
.sub-nav .link:hover,
.sub-nav .link.active {
    @apply border-pearl text-white;
}

.tab-list .tab-name {
    transition: all 0.3s linear;
}

.tab-list .tab-name:hover,
.tab-list .tab-name.active {
    @apply border-white text-white;
}

.tab {
    height: 585px;
}

.styled-scroll {
    scrollbar-width: thin; /* Firefox */
}
.styled-scroll::-webkit-scrollbar-track {
    -webkit-box-shadow: none !important;
    /* background-color: transparent; */
}
.styled-scroll::-webkit-scrollbar {
    background-color: transparent; 
    width: 5px;
}
.styled-scroll::-webkit-scrollbar-thumb {
    @apply bg-purple rounded-xl;
    width: 5px;
}
</style>
