const axios = require('axios').default;
const https = require('https')

const base_api_url = 'https://farm.kvdd.vn';
const httpsAgent = new https.Agent({ 
    // For DEBUG only 
    rejectUnauthorized: false
});

const urls = {
    // Prelogin
    login: '/user/login',
    authorize: '/user/authorize',

    // Post login

    // GET data
    me: '/me',
    birds: '/birds',
    lands: '/lands',
    otherLands: '/otherLands',

    // Post data
    updateSlot: '/updateSlot',
    useTool: '/useTool',
    collectEgg: '/collectEgg',
    collectTokens: '/collectToken',
    buyTool: '/buyTool',


    // Offering
    offeringPackages: '/offering/getPackages',
    buyOfferingPackage: '/offering/buyPackage',

    // Inventory
    updateMarketplaceStatus: '/market/checkTrans',
    hatchEgg: '/hatchEgg',
};

function loginMsg(login_nonce) {
    return 'Welcome to Kubebird.com, security login code: ' + login_nonce;
}

let connectionState = {};

let httpClient = axios.create({
    baseURL: base_api_url
});
httpClient.defaults.httpsAgent = httpsAgent;

function setToken(token) {
    httpClient.defaults.headers.common['Authorization'] = token;
};

function bindConnectionState(connection) {
    connectionState = connection;
}

function request(...args) {
    return httpClient.request(...args);
}

function get(...args) {
    return httpClient.get(...args); 
}

function post(...args) {
    return httpClient.post(...args);
}

function postJSON(url, json) {
    return post(url, json, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

function login(wallet_address) {
    return get(urls.login, {
        params: {
            wallet_address 
        }
    });
}

function authorize(login_nonce, wallet_address, signature) {
    return postJSON(urls.authorize, {
        login_nonce,
        wallet_address,
        signature
    });
}

function handleJsonResp(resp) {
    if (resp && resp.data && resp.data.status) {
        if (resp.data.status.indexOf('token') > -1) {
            // issue with token
            setToken(null);

            // Invalidate the token
            connectionState.isLoggedIn = false;
            connectionState.loggedInToken = null;
            connectionState.expireAt = null;
        }

        return resp.data.data;
    }
}

function asyncMe() {
    return get(urls.me);
}

async function me() {
    const resp = await asyncMe();
    return handleJsonResp(resp);
}

function asyncBirds() {
    return get(urls.birds);
}

function asyncTools() {
    return get(urls.tools);
}

function asyncLands() {
    return get(urls.lands);
}

function asyncOtherLand(x, y) {
    return get(urls.otherLands, {
        params: {
            location_x: x,
            location_y: y,
        }
    });
}

async function otherLand(x, y) {
    const resp = await asyncOtherLand(x, y);
    return handleJsonResp(resp); 
}

function asyncUseTool(slot_id, tool) {
    return postJSON(urls.useTool, {
        slot_id, tool
    });
}

async function useTool(slot_id, tool) {
    const resp = await asyncUseTool(slot_id, tool);
    return handleJsonResp(resp);  
}

function asyncUpdateSlot(slot_id, birds_id) {
    return postJSON(urls.updateSlot, {
        slot_id, birds_id 
    });
}

async function updateSlot(slot_id, birds_id) {
    const resp = await asyncUpdateSlot(slot_id, birds_id);
    return handleJsonResp(resp);  
}

function asyncCollectEgg(slot_id) {
    return postJSON(urls.collectEgg, {
        slot_id
    });
}

async function collectEgg(slot_id) {
    const resp = await asyncCollectEgg(slot_id);
    return handleJsonResp(resp);
}

function asyncCollectTokens(birds_id) {
    return postJSON(urls.collectTokens, {
        birds_id
    });
}

async function collectTokens(birds_id) {
    const resp = await asyncCollectTokens(birds_id);
    return handleJsonResp(resp);
}

function asyncBuyTool(tool, quantity, smcTx = null) {
    return postJSON(urls.buyTool, {
        tool, quantity, smcTx
    }); 
}

async function buyTool(tool, quantity, smcTx = null) {
    const resp  = await asyncBuyTool(tool, quantity, smcTx);
    return handleJsonResp(resp);
}

function asyncOfferingPackages() {
    return get(urls.offeringPackages);
}

async function offeringPackages() {
    const resp  = await asyncOfferingPackages();
    return handleJsonResp(resp); 
}

function asyncBuyOfferingPackage(packId, blockHash, blockNumber, contractAddress, from, to, transactionHash, type) {
    return postJSON(urls.buyOfferingPackage, {
        packId, blockHash, blockNumber, contractAddress, from, to, transactionHash, type
    });
}

async function buyOfferingPackage(packId, blockHash, blockNumber, contractAddress, from, to, transactionHash, type) {
    const resp  = await asyncBuyOfferingPackage(packId, blockHash, blockNumber, contractAddress, from, to, transactionHash, type);
    return handleJsonResp(resp);  
}

function asyncUpdateMarketplaceState(id, price, type, action) {
    return postJSON(urls.updateMarketplaceStatus, {
        id, price, type, action
    })
}

function asyncPlaceBirdToMarket(bird_id, price) {
    return asyncUpdateMarketplaceState(bird_id, price, 0, 0);
}

async function placeBirdToMarket(bird_id, price) {
    const resp = await asyncPlaceBirdToMarket(bird_id, price);
    return handleJsonResp(resp);  
}

function asyncUnplaceBirdFromMarket(bird_id) {
    return asyncUpdateMarketplaceState(bird_id, null, 0, 1);
}

async function unplaceBirdFromMarket(bird_id) {
    const resp = await asyncUnplaceBirdFromMarket(bird_id);
    return handleJsonResp(resp);     
}

function asyncPlaceLandToMarket(land_id, price) {
    return asyncUpdateMarketplaceState(land_id, price, 1, 0);
}

async function placeLandToMarket(land_id, price) {
    const resp = await asyncPlaceLandToMarket(land_id, price);
    return handleJsonResp(resp);  
}

function asyncUnplaceLandFromMarket(land_id) {
    return asyncUpdateMarketplaceState(land_id, null, 1, 1);
}

async function unplaceLandFromMarket(land_id) {
    const resp = await asyncUnplaceLandFromMarket(land_id);
    return handleJsonResp(resp);     
}

function asyncHatchEgg(egg_id, smcTx) {
    return postJSON(urls.hatchEgg, {
        egg_id, smcTx,
    })
}
async function hatchEgg(egg_id, smcTx) {
    const resp = await asyncHatchEgg(egg_id, smcTx);
    return handleJsonResp(resp);   
}

export default {
    urls,
    httpClient, 
    setToken, bindConnectionState,
    // Generic wrapper for axios
    request, get, post, postJSON,
    // Pre-login
    login, authorize, loginMsg,
    // Post-login:
    // GET
    me, otherLand,
    // POST
    useTool, updateSlot,
    collectEgg, collectTokens,
    buyTool,

    // Offering
    offeringPackages,
    buyOfferingPackage,

    // Marketplace
    placeBirdToMarket,
    unplaceBirdFromMarket,
    placeLandToMarket,
    unplaceLandFromMarket,
    hatchEgg,
}