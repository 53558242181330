const addresses = {
  mainToken: '0xddf9b62dbfbdd5d473bb89295843915d7f21cfed',
  buyPackageNFT: '0xEf7f59cfF02C4E5bB4bC9d80e8E3e676CaDa66de',
  birdMarket: '0x4328748cb6396B3B2f989396683DCce90AbECdd4',
  landMarket: '0xAe27b31E666CD8E6A74A2E30c866cd09066882c9',
  farm: '0x7e712B72D2058bB1a0dC277b29841547841CD0e2',
}

console.log('SMC address', addresses);
const abis = {}

abis.mainToken = require('@/helpers/abis/mainTokenContract.js').default;
abis.birdMarket = require('@/helpers/abis/birdMarketContract.js').default; 
abis.landMarket = require('@/helpers/abis/landMarketContract.js').default;

export default {
  abis,
  addresses,
  ethNum(small_num, w3) {
    if (typeof small_num !== 'string') {
      small_num = small_num.toString();
    }
    return w3.utils.toWei(small_num, 'ether');
  },
}
