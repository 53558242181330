import SMC from '@/helpers/SMC.js';

export default {
    data() {
        return {
            loadingBox: null,
        }
    },
    mounted() {
        // it's stupid but it works, it's not stupid
        setTimeout(() => {
            this.loadingBox = window.vApp.$refs.loadingBox;
        }, 200);  
    },
    methods: {
        getIndexById(items, id) {
            let index = -1;
            items.forEach((item, i) => {
                if (item.id == id) {
                    index = i;
                    return false;// short cut
                }
            });

            return index;
        },
        getItemById(items, id, whenEmpty = null) {
            const index = this.getIndexById(items, id);
            if (index === -1) {
                return whenEmpty;
            }

            return items[index];
        },
        showLoadingBox() {
            this.loadingBox && this.loadingBox.show();
        },
        hideLoadingBox() {
            this.loadingBox && this.loadingBox.hide();
        },
        roundDec(number, dec = 2) {
            number = Math.round(number + "e" + dec);
            return Number(number + "e" + -dec);
        },

        asyncGetFACToken(wallet_address) {
            if ( ! this.eth) {
                console.error('eth object was not init');
                return null;
            }

            try {
                return this.eth.w3.eth.getBalance(wallet_address); 
            } catch (ex) {
                console.error('Fail getting FAC token', ex);
            }
            
        },

        asyncGetMainToken(wallet_address) {
            if ( ! this.eth) {
                console.error('eth object was not init');
                return null;
            }
            
            try {
                const mainTokenContract = new this.eth.w3.eth.Contract(SMC.abis.mainToken, SMC.addresses.mainToken);
                return mainTokenContract.methods.balanceOf(wallet_address).call();
                

            } catch (ex) {
                console.error('Fail getting Main token ', ex);
            }   
        },

        async getTokens(wallet_address) {
            const vals = await Promise.all([
                this.asyncGetFACToken(wallet_address),
                this.asyncGetMainToken(wallet_address),
            ]);
            // console.log('getTokens ret', vals);
            
            return {
                FAC: this.roundDec(vals[0] / 10**18, 2),
                KUB: this.roundDec(vals[1] / 10**18, 2), 
            }
        },

        async updateTokens() {
            if ( ! this.eth) {
                console.error('updateToken fail - eth object wasnt init');
                return false;
            }

            if ( ! this.tokens) {
                console.error('updateToken fail - no inject of tokens obj');
                return false;
            }

            const tokens = await this.getTokens(this.eth.walletAddress);
            this.tokens.FAC = tokens.FAC;
            this.tokens.KUB = tokens.KUB;

            return true;
        }
    }
}