<template>
    <header class="o1 flex bg-black-light absolute top-0 left-0 w-full z-20">
        <div class="logo-wrap relative w-54 h-25 o1">
            <img src="https://via.placeholder.com/200x100" class="w-52 h-25 absolute 2xl:-right-16 " alt="">
        </div>
        <div class="container relative flex h-25 text-lg o5">
            
            <nav class="main-nav block mx-auto text-center text-gray">
                <a href="#" class="link inline-block px-7 py-4">Dashboard</a>
                <a href="#" class="link inline-block px-7 py-4">Marketplace</a>
                <a href="/farm/" class="link inline-block px-7 py-4">Farm</a>
                <router-link to="/offering" class="link inline-block px-7 py-4">Offers</router-link>
            </nav>
            <a href="#" class="cart block absolute right-0 top-7 w-11 h-11 rounded-md bg-pearl">
                <!-- <div class="cart-count absolute w-6 h-6 -top-3 -right-3 pt-0.5 rounded-half bg-purple-dark text-center text-sm">49</div> -->
            </a>
            <div class="login-state absolute -right-52 w-52 pl-12 o5">
                <a v-if=" ! uiStates.connection.isLoggedIn" href="#" class="absolute top-9 btn inline-block py-0.5 px-3"
                    @click.prevent="performLogin()" >Connect Wallet</a>
                <a v-else href="/my-account" class="my-account absolute flex top-9">
                    <figure class="avatar w-7.5 h-7.5 border border-pearl rounded-md overflow-hidden">
                        <img src="https://via.placeholder.com/30x30" class="" alt=""> 
                    </figure>
                    <span class="t text-pearl ml-2">My Account</span>
                </a>
                <a href="#" @click.prevent="debug" class="text-white text-sm">Debug</a>
            </div>
        </div>
    </header>
</template>

<script>
import ShareMethods from '@/mixins/ShareMethods.js';
import API from '@/helpers/Api.js';
import SMC from '@/helpers/SMC.js';
import { inject } from 'vue';
import Web3 from 'web3';
window.Web3 = Web3;

export default {
    name: 'SiteHeader',
    mixins: [ShareMethods],
    emits: ['loginSuccess'],
    setup() {
        return {
            uiStates: inject('uiStates'),
            eth: inject('eth'),
            user: inject('user'),
            tokens: inject('tokens'),
        }
    },
    methods: {
        async connectMetamask() {
            console.log('Connect Metamask');
            
            if (this.eth.connected === true) {
                return true;
            }

            if (typeof window.ethereum !== 'undefined') {
                // Instance web3 with the provided information
                let web3 = new Web3(window.ethereum);
                
                try {
                    // Request account access
                    await window.ethereum.enable();
                    window.w3 = web3;
                    this.eth.connected = true;
                    this.eth.w3 = web3;
                    
                    this.uiStates.connection.walletConnected = true;

                    

                    return true;
                } catch(e) {
                    // User denied access
                    this.eth.connected = false;
                    console.error('Connect Metamask fail', e);
                    return false;
                }
            }

            return false;
        },

        async getWalletAddress() {

            console.log('Get wallet address');
            
            if (this.eth.walletAddress) {
                return this.eth.walletAddress;
            }

            if ( ! this.eth.connected) {
                // attempt to connect if not connected
                await this.connectMetamask();
            }
            
            if (this.eth.connected) {
                let account = await window.ethereum.request({ method: 'eth_accounts' });
                this.eth.walletAddress = account.toString();
                return this.eth.walletAddress;
            }            
        },

        async signMessage(msg) {
            console.log('Sign message ...');
            
            if ( ! this.eth.connected) {
                // attempt to connect if not connected
                await this.connectMetamask();
            }
            
            let wallet_address = await this.getWalletAddress();

            if (this.eth.connected) {
                const w3 = this.eth.w3;
                return await w3.eth.personal.sign(w3.utils.fromUtf8(msg), wallet_address);
            }    
        },

        async performLogin() {
            console.log('Perform login');
            
            this.showLoadingBox();

            const wallet_address = await this.getWalletAddress();
            if ( ! wallet_address) {
                alert('Fail to retrieve Wallet Address');
                this.hideLoadingBox();
                return false;
            }

            
            console.log('User wallet', wallet_address);

            // get login_nonce
            let login_nonce = null;
            try {
                let resp = await API.login(wallet_address);
                console.log('Response', resp);
                login_nonce = resp.data.toString();
            } catch (ex) {
                console.error(ex);
                this.hideLoadingBox();
                return false;
            }
            
            console.log('Login nonce from server', login_nonce);
            
            // Attempt signing
            let signature = null;
            try {
                signature = await this.signMessage(API.loginMsg(login_nonce));   
                console.log('Signed login request', signature);
            } catch (ex) {
                console.error('Rejected to sign login request', ex);
                this.hideLoadingBox();
                return false;
            }

            
            // Authorize the request
            let jwt = null;
            try {
                const tmp_resp = await API.authorize(login_nonce, wallet_address, signature); 
                console.log('Authorize resp', tmp_resp);
                jwt = tmp_resp.data.toString();
                console.log('JWT token', jwt);

                this.uiStates.connection.isLoggedIn = true;
                this.uiStates.connection.loggedInToken = jwt;
                this.uiStates.connection.walletAddress = wallet_address;
                this.uiStates.connection.expireAt = Date.now() + 43200000; // BE says 24 hrs, but I'd like to reduce to 12hrs

                API.setToken(jwt);

                // THIS IS MAGIC
                API.bindConnectionState(this.uiStates.connection); 
                
                // Fetch token from wallet
                const tokens = await this.getTokens(this.eth.walletAddress);
                this.tokens.FAC = tokens.FAC;
                this.tokens.KUB = tokens.KUB;

                this.hideLoadingBox();

                console.info('Login success');
                
                this.$emit('loginSuccess');
                
            } catch (ex) {
                console.error('Login fail', ex);
                this.hideLoadingBox();
                return false;
            }
        },

        performLogout() {
            this.uiStates.connection.walletConnected = false;
            this.uiStates.connection.isLoggedIn  = false;
            this.uiStates.connection.loggedInToken = null;
            this.uiStates.connection.walletAddress = null; 
        },

        async debug() {
            debugger;            
        },
    },
}
</script>