<template>
    <teleport to="body">
        <transition name="lightbox-trans">
            <div v-if="isShown" class="lightbox-backdrop" :class="backdropClass" :style="{ zIndex: zIndex }"
                @click.stop.prevent="_backdropClick">

                <div class="modal relative" :class="modalClass">
                    <slot>Your modal box content</slot>
                </div>
            </div>
        </transition>
    </teleport>
</template>

<script>
export default {
    name: 'LightBox',
    props: {
        backdropClose: {
            default: true,
        },
        showOnCreated: {
            default: false,
        },
        backdropClass: {
            default: 'bg-black bg-opacity-30 p-5',
        },
        modalClass: {
            default: 'bg-white p-5',
        },
        zIndex: {
            default: 50,
        },
    },
    data() {
        return {
            isShown: !! this.showOnCreated,
        };
    },
    emits: ['shown', 'closed'],
    methods: {
        _backdropClick(e) {
            if (this.backdropClose) {
                if (e.target.classList.contains('lightbox-backdrop')) {
                    this.hide();
                }                
            }
        },
        show() {
            this.isShown = true;
            document.body.classList.add('lightbox-on')
            this.$emit('shown');
        },
        hide() {
            this.isShown = false;
            document.body.classList.remove('lightbox-on');
            this.$emit('closed');
        }
    },
}
</script>

<style lang="postcss">

body.lightbox-on {
    overflow: hidden;
}

.lightbox-backdrop {
    @apply fixed top-0 left-0 right-0 bottom-0 overflow-x-hidden overscroll-y-auto flex justify-center items-center z-50;
}

.lightbox-trans-enter-from,
.lightbox-trans-leave-to {
    opacity: 0;    
}
.lightbox-trans-enter-active,
.lightbox-trans-leave-active {
    transition: opacity 0.5s ease;
}

</style>